import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    margin: '0 0 12px 0',
    color: '#7833FF',
  },
  infoText: {
    color: '#170E09',

    '& a': {
      textDecoration: 'none',
    },
  },
  itemContainer: {
    marginTop: 0,
    marginBottom: 0,
  },
  item: {
    textAlign: 'center',

    '& img': {
      height: 75,
    },

    '& h2': {
      color: '#7833FF',
      fontSize: 20,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      marginTop: 17,
    },
  },
  desktopText: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'inline',
    },
  },
  mobileText: {
    display: 'inline',

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  subTextContent: {
    fontFamily: 'Arial',
    fontSize: 16,
    marginTop: 17,
  },
}));
