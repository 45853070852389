import clsx from 'clsx';
import React from 'react';
import { Grid, Box, Typography, Container, Link } from '@mui/material';
import { useStyles } from './styles';
import LazyLoad from 'react-lazyload';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import CustomButton from 'components/CustomButton';
import Image from 'next/image';
import { getEnv } from 'utils';

const DefaultProps = {
  title: <b>Your Creations, Ready within Minutes!</b>,
  variantTitle: 'h4',
  variantInfoText: 'body1',
  showButton: false,
  classes: {},
  ctaButton: false,
};

export default function Infodesign({
  title,
  subTitle,
  variantTitle,
  variantInfoText,
  showButton,
  classes: propClasses,
  ctaButton,
}) {
  const classes = useStyles();
  const router = useRouter();

  return (
    <Box px={6} py={8} bgcolor="#FAFAFA">
      <Container>
        <LazyLoad offset={100}>
          <Grid item xs={12}>
            <Typography
              variant={variantTitle}
              component="h2"
              align="center"
              className={classes.title}
            >
              {title}
            </Typography>

            <Box textAlign="center">
              <Typography
                variant={variantInfoText}
                className={clsx(classes.infoText, propClasses.infoText)}
              >
                <Typography
                  variant={variantInfoText}
                  className={clsx(classes.infoText, propClasses.infoText)}
                >
                  {subTitle || (
                    <>
                      We built Mind the Graph for simplicity. The platform is easy to use and just
                      about <br />
                      anybody can use it to{' '}
                      <Link
                        className={clsx(classes.infoText, propClasses.infoText)}
                        href={`${getEnv('MTG_URL_APP')}/`}
                        underline="hover"
                      >
                        {' '}
                        create great infographics{' '}
                      </Link>{' '}
                      - from beginners <br /> to professionals, individuals to groups and small labs
                      to large organizations
                    </>
                  )}
                </Typography>
              </Typography>
            </Box>
          </Grid>

          <Grid className={classes.itemContainer} container={true} spacing={8} alignItems="center">
            <Grid item={true} xs={12} sm={6} lg={4}>
              <Box className={classes.item}>
                <Image
                  src="/images/drag-drop-icon.webp"
                  loading="lazy"
                  height={75}
                  width={69.16}
                  alt="Drag and Drop"
                />
                <Typography component="h2">Drag and Drop</Typography>
                <Typography className={classes.subTextContent}>
                  Easily select and manage
                  <br />
                  illustrations, text and templates
                </Typography>
              </Box>
            </Grid>

            <Grid item={true} xs={12} sm={6} lg={4}>
              <Box className={classes.item}>
                <Image
                  src="/images/premade-icon.webp"
                  loading="lazy"
                  height={75}
                  width={76.66}
                  alt="Pre-made resources"
                />
                <Typography component="h2">Pre-Made Resources</Typography>
                <Typography className={classes.subTextContent}>
                  Don't waste time! Use our templates
                  <br />
                  and just customize colours, text etc.
                </Typography>
              </Box>
            </Grid>

            <Grid item={true} xs={12} sm={6} lg={4}>
              <Box className={classes.item}>
                <Image
                  src="/images/slides-icon.webp"
                  loading="lazy"
                  height={75}
                  width={75}
                  alt="Slides presentation"
                />
                <Typography component="h2">Slides Presentations</Typography>
                <Typography className={classes.subTextContent}>
                  Integrate information and
                  <br />
                  illustrations into beautiful and impactful slides
                </Typography>
              </Box>
            </Grid>

            <Grid item={true} xs={12} sm={6} lg={4}>
              <Box className={classes.item}>
                <Image
                  src="/images/multiple-sizing-options-icon.webp"
                  loading="lazy"
                  height={75}
                  width={73.27}
                  alt="Pre-set sizes"
                />
                <Typography component="h2">Multiple Sizing Options</Typography>
                <Typography className={classes.subTextContent}>
                  Ready-to-go templates in all popular
                  <br />
                  sizes
                </Typography>
              </Box>
            </Grid>

            <Grid item={true} xs={12} sm={6} lg={4}>
              <Box className={classes.item}>
                <Image
                  src="/images/ondemand-icon.webp"
                  loading="lazy"
                  height={75}
                  width={61.42}
                  alt="On-demand illustrations"
                />
                <Typography component="h2">On-demand Illustrations</Typography>
                <Typography className={classes.subTextContent}>
                  Don't find exactly what you need?
                  <br />
                  We will design it for you!
                </Typography>
              </Box>
            </Grid>

            <Grid item={true} xs={12} sm={6} lg={4}>
              <Box className={classes.item}>
                <Image
                  src="/images/online-platform-icon.webp"
                  loading="lazy"
                  height={75}
                  width={76.75}
                  alt="Online plataform"
                />
                <Typography component="h2">Online Platform</Typography>
                <Typography className={classes.subTextContent}>
                  Access your creations at any time
                  <br />
                  and from anywhere
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {showButton && (
            <Box textAlign="center">
              {!ctaButton ? (
                <NextLink
                  href={`/auth/login-register?from_url=${router.basePath + router.asPath}`}
                  passHref={true}
                >
                  <CustomButton>Start creating now</CustomButton>
                </NextLink>
              ) : (
                <CustomButton variant="ctaPrimaryOpenModal">Start creating now</CustomButton>
              )}
            </Box>
          )}
        </LazyLoad>
      </Container>
    </Box>
  );
}

Infodesign.defaultProps = DefaultProps;
